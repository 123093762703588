
$(function () {
  $('.record-audio').on('click', function(e) {
    var framingContext = e.target.closest('.recordingframe');
    recordAudio(framingContext);
  })

  $('.stop-recording-audio').on('click', function(e) { 
    var framingContext = e.target.closest('.recordingframe');
    stopRecording(framingContext);
  })

  $('.delete_audio').on('click', function(e) {
    var caller = e.target;
    var idToDelete = caller.attributes.dataid.value;
    deleteAudio(idToDelete);
  })

});

var audioChunks = [];
var rec = null;
var startedAt = null;
var timerId = null;
var timerContext = null;

function updateTimer() {
  var newTime = ((new Date() - startedAt) / 1000).toFixed(0);
  var minutes = Math.floor(newTime / 60);
  var seconds = newTime % 60;
  var secondsText = "" + seconds;
  if (seconds < 10) {
    secondsText = "0" + secondsText;
  }
  var fullText = minutes + ":" + secondsText;
  $(timerContext).find('.recordingtime').html('Recording ' + fullText);
}

function recordAudio(framingContext) {
  timerContext = framingContext;
  if (!(rec)) {
    $(framingContext).find('.record-audio').hide();
    $(framingContext).find('.recording-in-progress').show();
    $('#btn-savereflect').hide();

    startedAt = new Date();
    timerId = setInterval(updateTimer, 500);

    navigator.mediaDevices.getUserMedia({audio:true}).then((stream) => {handleAudio(stream)});
  }
}

function stopRecording(framingContext) {
  rec.stop();
  $(framingContext).find('.record-audio').show();
  $('.recording-in-progress').hide();

  clearInterval(timerId);
}

function handleAudio(stream) {
  if (!(rec)) {
    rec = new MediaRecorder(stream);
    rec.start();
    rec.ondataavailable = (e) => {
      audioChunks.push(e.data);
      if (rec.state == "inactive") {
        let blob = new Blob(audioChunks, {type: rec.mimeType});
        sendAudio(blob);
      }
    }
  }
}

const sendAudio = async (audioBlob) => {
  // set up form
  let fd = new FormData();
  fd.append('reflection[audio]', audioBlob);
  let csrftoken = $('input[name="authenticity_token"]').val();
  fd.append('authenticity_token', csrftoken);
  let gameid = $('#game_id').val()
  fd.append('game_id', gameid)
  let cardid = $('#cardid').val();
  if (!(cardid)) {
    cardid = $(timerContext).attr('data-cardid');
  }
  fd.append('card_id', cardid);

  // submit data
  let response = await fetch('/reflectionaudio', { method: "POST", body: fd})

  $('#btn-savereflect').show();

  // update UI
  if ($('#reload').val() === 'yes') {
    document.location.reload();
  }
}

const deleteAudio = async (audioKey) => {  
  if (confirm('Are you sure? This deletion is permanent.')) {
    
    let fd = new FormData();
    let csrftoken = $('input[name="authenticity_token"]').val();
    fd.append('authenticity_token', csrftoken);
    let gameid = $('#game_id').val()
    fd.append('game_id', gameid)
    let cardid = $('#cardid').val();
    if (!(cardid)) {
      cardid = $(timerContext).attr('data-cardid');
    }
    fd.append('card_id', cardid);

    let response = await fetch('/reflectionaudiodelete/' + audioKey, {method: "DELETE", body: fd})

    if ($('#reload').val() === 'yes') {
      document.location.reload();
    }
  }
}
