$(function () {
  $('#nav-buttons').delay(500).fadeIn();
  $('#review-button').delay(1000).fadeIn();

  // intro page 'slideshow'
  if ($('#intro1').length > 0) {
    
    $('#continue1').on('click', function() {    
      showIntro2();
    });
    $('#continue2').on('click', function() {    
      startGame();
    });
    showIntro();
  }

  //FYI functions can't be called directly from page anymore - they have to be done through decorators
  $('#btn-nextcard').on('click', function() {    
    getNextCard();    
  });

  $('#btn-review').on('click', function() {
    goReview();
  })

  $('#btn-reflect').hide();
  $('#btn-reflect').on('click', function() {    
    showReflection();
  });
 
  $('#reflectionform').on('submit', function() {
    submitReflection();
  })

  $('.notes_edit').on('click', function(e) {
    editNotes(e);
  });

  $('.updatenoteform').on('submit', function(e) {
    updateNotes(e);
  });

  $('#select_all').on('click', function() {
    $('.card_used_check').prop('checked', true);
  });
  
  $('#deselect_all').on('click', function() {
    $('.card_used_check').prop('checked', false);
  });

  $('.edit_recording_label').on('click', function(e) {
    var caller = e.target;
    var idToEdit = caller.attributes.dataid.value;
    $('#label_' + idToEdit + '_edit').show();
    $('#label_' + idToEdit + '_read').hide();
  });

  $('.updatelabelform').on('submit', function(e) {
    var caller = e.target;
    var idToUpdate = caller.attributes.dataid.value;
    console.log('Updating ' + idToUpdate);
    labelText = $('#label_' + idToUpdate).val();
    $('#label_text_' + idToUpdate).html(labelText);
    $('#label_' + idToUpdate + '_edit').hide();
    $('#label_' + idToUpdate + '_read').show();
  });
});

function showIntro() {
  $('#titletext').fadeIn(1000);
  $('#titletext').delay(3000).fadeOut(1000);
  $('#introtext').delay(6000).fadeIn(1000);
  $('#introtext').delay(8500).fadeOut(1000);
  $('#cardinstructions').delay(16500).fadeIn(1000);
  $('#cardinstructions').delay(4000).fadeOut(1000);

  setTimeout(showIntro1, 22500);
}

function showIntro1() {
  // show card back
  $('#intro1').delay(200).fadeIn(700);

  // cardflip
  $('#intro1back').delay(1500).fadeOut(350);
  $('#intro1front').delay(1900).fadeIn();

  $('#continue1').delay(3000).fadeIn(1000);
}
function showIntro2() {
  $('#intro1').fadeOut();
  $('#intro2').delay(500).fadeIn();

  $('#intro2back').delay(2000).fadeOut(350);
  $('#intro2front').delay(2400).fadeIn();

  $('#continue2').delay(3000).fadeIn(1000);
}

function startGame() {
  document.location.href = document.location.href + '?start=y'
}

function getNextCard() {
  setupForNextCard();
  var sectionName = $('#section').html();
  var gameId = $('#gameid').html();
  $.get('/nextcard/' + gameId + "/" + sectionName, function(data) {
    setTimeout(showNewCard(data), 1000);
  });
}

function getPreviousCard() {
  setupForNextCard();
  var sectionName = $('#section').html();
  var gameId = $('#gameid').html();
  var cardId = $('#cardid').val();
  $.get('/prevcard/' + gameId + "/" + cardId, function(data) {
    setTimeout(showNewCard(data), 1000);
  });
}

function goReview() {
  document.location.href = document.location.origin + '/gamereview/' + $('#gameid').html();
}

function setupForNextCard() {
  $('#cardslotbackground').hide();
  $('#btn-reflect').hide();
  $('#btn-prevcard').hide();
  $('#btn-nextcard').hide();
  $('#cardfront').hide();
  $('#cardback').show();
  $('#cardframe').hide();  
}

function showNewCard(cardData) {
  if (!!(cardData)) {
    // if a rewind the card is a subset of the main 
    if (cardData.card) {
      $('#reflection').val(cardData.notes);
      cardData = cardData.card;
    }
    $('#cardframe').fadeIn();
    $('#card_name').html(cardData.title);
    $('#card_details').html(cardData.details);
    $('#card_number').html(cardData.number);
    $('#cardid').val(cardData.id);
    
    setTimeout(flipCard, 1500);
  } else {
    $('#nomore').fadeIn(500);
  }
}

function flipCard() {
  $('#cardback').fadeOut(350);
  $('#cardfront').delay(400).fadeIn();
  $('#btn-reflect').delay(2000).fadeIn();
}

function showReflection() {
  
  $('#reflectionslot').fadeIn();
  $('#btn-reflect').hide();
  $('#btn-nextcard').hide();
}

function submitReflection() {
  $('#reflectionslot').fadeOut(250);
  $('#cardframe').fadeOut(300);
  setTimeout(setupForButton, 1000);
}

function setupForButton() {
  $('#cardslotbackground').fadeIn();
  $('#reflection').val('');
  $('#btn-reflect').hide();
  $('#btn-nextcard').show();
  $('#btn-prevcard').show();
  $('#cardfront').hide();
  $('#cardback').show();
  $('#cardframe').hide();
}

function editNotes(e) {
  var caller = e.target;
  var idToEdit = caller.attributes.dataid.value;
  $('#updatenote_' + idToEdit).show();
  $('#notes_edit_' + idToEdit).hide();
  $('#notes_read_' + idToEdit).hide();  
}

function updateNotes(e) {
  var caller = e.target;
  var idToUpdate = caller.attributes.dataid.value;
  $('#updatenote_' + idToUpdate).hide();
  $('#notes_edit_' + idToUpdate).show();
  $('#notes_read_' + idToUpdate).show();

  // convert newlines in newNote to <br>
  var newNote = $('#notes_' + idToUpdate).val();
  newNote = newNote.replace(/\n/g, "<br>");

  $('#notes_read_' + idToUpdate).html(newNote);
}